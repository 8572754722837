import moment from 'moment';
import {BusinessConfig} from "../constants/business";
import DATE_FORMAT from "../constants/dateFormat";
import {ConfigKeys, getConfigValue} from "./firebase";

export const SECONDS_IN_DAY = 24 * 60 * 60;

/**
 * source: https://gist.github.com/johndyer/0dffbdd98c2046f41180c051f378f343
 *
 * Calculates Easter in the Gregorian/Western (Catholic and Protestant) calendar
 * based on the algorithm by Oudin (1940) from http://www.tondering.dk/claus/cal/easter.php
 * @returns moment.Moment
 */
export const getEaster = (year) => {
    const f = Math.floor;
    // Golden Number - 1
    const G = year % 19;
    const C = f(year / 100);
    // related to Epact
    const H = (C - f(C / 4) - f((8 * C + 13)/25) + 19 * G + 15) % 30;
    // number of days from 21 March to the Paschal full moon
    const I = H - f(H/28) * (1 - f(29/(H + 1)) * f((21-G)/11));
    // weekday for the Paschal full moon
    const J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7;
    // number of days from 21 March to the Sunday on or before the Paschal full moon
    const L = I - J;
    const month = 3 + f((L + 40)/44);
    const day = L + 28 - 31 * f(month / 4);

    const dateStr = `${day}.${month}.${year}`;
    return moment(dateStr, DATE_FORMAT);
}


export const isVacationFunc = (date, vacation = []) => vacation.length === 2 && date.isBetween(vacation[0], vacation[1], 'days', '[]');
export const isHolidayFunc = (date, holidays) => !!holidays.filter((h) => date.isSame(h, 'days')).length;
export const isWeekendFunc = (d) => d.day() === 0 || d.day() === 6;

export const isWorkingDay = (day, workOnWeekends, workOnHolidays, holidays, vacation = []) => {
    const isWeekend = isWeekendFunc(day);
    const isVacation = isVacationFunc(day, vacation);
    const isHoliday = isHolidayFunc(day, holidays);
    return !isVacation && (!isWeekend && !isHoliday || workOnWeekends && isWeekend || workOnHolidays && isHoliday);
}

/**
 * Get consumption ratios as kg/MWh
 */
const parseConsumptionRatios = () => {
    let ratios = getConfigValue(ConfigKeys.CONSUMPTION_RATIOS);
    if (ratios) {
        return JSON.parse(ratios);
    }
}

/**
 * Ratios is a map of year => ratio.
 * Returns ratio for given year or for last year in configuration.
 * 
 * E.g.
 * ratios = {
 *  "1999": 597.85,
 *  "2000": 600,
 *  "2001": 700
 * }
 * 
 * for 2002 will return 700 (last year)
 */
export const consumptionRatioForYear = (year) => {
    const ratios = parseConsumptionRatios();
    if (ratios[year] !== undefined) {
        return ratios[year];
    }
    const sortedYears = Object.keys(ratios).sort();
    if (year < Number(sortedYears[0])) {
        return ratios[sortedYears[0]];
    }
    if (year > Number(sortedYears[sortedYears.length - 1])) {
        return ratios[sortedYears[sortedYears.length - 1]];
    }
    sortedYears.forEach((y, index) => {
        if (Number(y) > year) {
            return ratios[index - 1];
        }
    });
}

/**
 * Return array of daily emissions (in kg) for given month.
 * Negative emission means it is a vacation day.
 * @param {*} month mesic 1..12
 * @param {*} year rok
 * @param {*} vacation dovolena
 * @param {*} holidays svatky
 * @param {*} workOnWeekend vyroba o vikendu
 * @param {*} workOnHolidays vyroba o svatcich
 * @param {*} holidayConsumption spotreba o svatcich (% z denni spotreby v pracovni den)
 * @param {*} monthlyConsumption spotreba MWh pre dany mesiac
 * @param {*} emissionRatio koeficient emisii v kg/MWh
 * @param {*} workSecondsPerWorkDay how many seconds does a work day have
 * 
 * Returns an array with element for each day of given month. The structure is
 * {
 *   isWorkDay: boolean;
 *   emissionPerWorkSecond: number; // in kg
 *   emissionPerNonWorkSecond: number; // in kg
 *   totalDailyEmission: number; // in kg
 * }
 */
const emissionsForMonth = (month, year, vacation, holidays, workOnWeekend, workOnHolidays, holidayConsumption, monthlyConsumption, emissionRatio, workSecondsPerWorkDay) => {
    let date = moment().year(year).month(month - 1).date(1);
    const emissions = [];
    let workingDays = 0;
    let nonWorkConsumptionRatio = holidayConsumption;

    while (date.month() === month - 1) {
        const rec = {
            isWorkDay: false,
            emissionPerWorkSecond: undefined,
            emissionPerNonWorkSecond: undefined,
            totalDailyEmission: undefined
        };
        if (isWorkingDay(date, workOnWeekend, workOnHolidays, holidays, vacation)) {
            rec.isWorkDay = true;
            workingDays++;
        }
        emissions.push(rec);
        date.add(1, 'days');   
    }

    const daysInMonth = emissions.length;
    const workSecondsInMonth = workingDays * workSecondsPerWorkDay;
    const nonWorkSecondsInMonth = daysInMonth * SECONDS_IN_DAY - workSecondsInMonth;
    // avoid division by 0
    nonWorkConsumptionRatio = workSecondsInMonth === 0 && nonWorkConsumptionRatio === 0 ? 1 : nonWorkConsumptionRatio;
    const consumptionPerWorkingSecond = monthlyConsumption / (workSecondsInMonth + nonWorkConsumptionRatio * nonWorkSecondsInMonth);
    const emissionsPerWorkingSecond = consumptionPerWorkingSecond * emissionRatio;

    emissions.forEach((_, index) => {
        emissions[index].emissionPerWorkSecond = emissionsPerWorkingSecond;
        emissions[index].emissionPerNonWorkSecond = emissionsPerWorkingSecond * nonWorkConsumptionRatio;
        emissions[index].totalDailyEmission = emissions[index].isWorkDay
            ? workSecondsPerWorkDay * emissionsPerWorkingSecond + (SECONDS_IN_DAY - workSecondsPerWorkDay) * emissions[index].emissionPerNonWorkSecond
            : SECONDS_IN_DAY * emissions[index].emissionPerNonWorkSecond;
    });

    return emissions;
}

/**
 *
 * @param consumption Spotreba MWh
 * @param business odvetvi
 * @param vacation dovolena
 * @param workOnWeekend vyroba o vikendu
 * @param workOnHolidays vyroba o svatcich
 * @param year rok
 * @param holidays svatky
 * @returns array of daily emissions
 */
export const dailyEmissionsForYear = (consumption, business, vacation, workOnWeekend, workOnHolidays, year, holidays, holidayConsumption, shiftFrom, shiftTo) => {
    const emissions = [];
    const shiftSecondsInDay = shiftFrom < shiftTo ? shiftTo - shiftFrom : SECONDS_IN_DAY - shiftFrom + shiftTo;
    const emissionRatio = consumptionRatioForYear(year);

    const Vm = consumption / 12; // Spotreba za mesic
    for (let m = 1; m <= 12; m++) {
        const Vmi = Vm * BusinessConfig[business][m - 1]; // Spotreba za mesic pro dany business
        emissions.push(...emissionsForMonth(m, year, vacation, holidays, workOnWeekend, workOnHolidays, holidayConsumption, Vmi, emissionRatio, shiftSecondsInDay));
    }
    return emissions;
}

/**
 * 
 * @param {*} dailyEmissions array of daily emissions
 * @param {*} shiftFrom beginning of shift as # of second from start of day
 * @param {*} shiftTo end of shift as # of seconds from start of day
 * @param {*} startDate start date since when to calculate emissions
 */
export function calculateCurrentEmissions(dailyEmissions, shiftFrom, shiftTo, startDate) {
    const now = moment();
    if (now.isBefore(startDate)) {
        return 0;
    }
    const dayOfYearNow = now.dayOfYear() - 1;
    let secondsSinceSOD = now.diff(now.clone().startOf('day'), 'seconds');
    // avoid division by 0
    secondsSinceSOD = secondsSinceSOD ? secondsSinceSOD : 1;

    if (dailyEmissions[dayOfYearNow].isWorkDay) {
        // Non-shift emission is a percentage of shift emission.
        // I use the same percentage as for holidayConsumption
        if (shiftFrom !== shiftTo) {
            const emissionPerWorkSecond = dailyEmissions[dayOfYearNow].emissionPerWorkSecond;
            const emissionPerNonWorkSecond = dailyEmissions[dayOfYearNow].emissionPerNonWorkSecond;
            let shiftSecondsTillNow = 0;
            let nonShiftSecondsTillNow = 0;
            // a normal case where shifts happen in 1 day
            if (shiftFrom < shiftTo) {
                if (secondsSinceSOD < shiftFrom) {
                    // There were only non-shift seconds till now
                    nonShiftSecondsTillNow = secondsSinceSOD;
                } else if (secondsSinceSOD > shiftTo) {
                    // there was a full shift of seconds, non-shift seconds block from SOD until start of shift and block of non-shift seconds from end of shift till now
                    nonShiftSecondsTillNow = shiftFrom + secondsSinceSOD - shiftTo;
                    shiftSecondsTillNow = shiftTo - shiftFrom;
                } else {
                    // there was a block of non-shift seconds until start of shift and then shift seconds from shift start till now
                    nonShiftSecondsTillNow = shiftFrom;
                    shiftSecondsTillNow = secondsSinceSOD - shiftFrom;
                }
            } else {
                // shift starts today but ends tomorrow
                if (secondsSinceSOD < shiftTo) {
                    // there were only shift seconds till now
                    shiftSecondsTillNow = secondsSinceSOD;
                } else if (secondsSinceSOD > shiftFrom) {
                    // there was a block of shift seconds from SOD till end of shift, all non-shift seconds and block of shift seconds from start of shift till now
                    nonShiftSecondsTillNow = shiftFrom - shiftTo;
                    shiftSecondsTillNow = shiftTo + secondsSinceSOD - shiftFrom;
                } else {
                    // there was a block of shift seconds from SOD till end of shift and a block of non-shift seconds from end of shift till now
                    nonShiftSecondsTillNow = secondsSinceSOD - shiftTo;
                    shiftSecondsTillNow = shiftTo;
                }
            }
            return emissionPerNonWorkSecond * nonShiftSecondsTillNow + emissionPerWorkSecond * shiftSecondsTillNow;
        } else {
            // non-stop production
            return dailyEmissions[dayOfYearNow].emissionPerWorkSecond * secondsSinceSOD;
        }
    } else {
        // non-work day production
        return dailyEmissions[dayOfYearNow].emissionPerNonWorkSecond * secondsSinceSOD;
    }
}

export function humanizeWeight(valueInKg) {
    if (valueInKg < 1) {
        return [valueInKg * 1000, 'g'];
    }

    if (valueInKg > 1 && valueInKg < 9999) {
        return [valueInKg, 'kg'];
    }

    if (valueInKg > 9999) {
        return [valueInKg / 1000, 'tun'];
    }
}

export function humanizeArea(areaInHa) {
    if (areaInHa < 1) {
        return [areaInHa * 10000, 'm2'];
    }

    if (areaInHa > 1) {
        return [areaInHa, 'ha'];
    }
}

export function humanizeDistance(distanceInM) {
    if (distanceInM < 1) {
        return [distanceInM * 100, 'cm'];
    }

    if (distanceInM > 1 && distanceInM < 9999) {
        return [distanceInM, 'm'];
    }

    return [distanceInM / 1000, 'km'];
}

export function emissionsAsCars(valueInKg) {
    return valueInKg / 1348;
}

/**
 * Calculate how much coal is saved. Emissions are turned into consumption and then into coal savings.
 * @param {*} valueInKg emissions in kg
 * @param {*} consumptionRatio for calculating consmption from emissions, in kg/MWh
 */
export function emissionsAsCoal(valueInKg, consumptionRatio) {
    return humanizeWeight(valueInKg / consumptionRatio * 940);
}

/**
 * Returns value in ha
 */
export function emissionsAsForrest(valueInKg) {
    return valueInKg / 8082.43;
}

export function emissionsAsSoccer(forrestAreaInHa) {
    // area of Barcelona soccer field Cam Nou is 105mx68m
    return forrestAreaInHa * 10000  / (105*68);
}

export function emissionsAsBalloons(valueInKg) {
    return valueInKg / (2800 / 0.5562);
}

export function emissionsAsChimney(valueInKg) {
    return humanizeDistance(valueInKg * 556.2 / 1000);
}

/**
 * numAsStr is a float with '.' as decimal separator.
 * This function returns a tupple [part in front of comma, part after comma]
 */
export function numParts(numAsStr) {
    if (numAsStr === null || numAsStr === undefined || numAsStr === '.') {
        return ['', ''];
    }
    const parts = numAsStr.split('.');
    if (parts.len === 1) {
        return [parts[0], ''];
    }
    return [parts[0], parts[1]];
}
