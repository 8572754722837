import {Business} from "../constants/business";
import moment from "moment";
import DATE_FORMAT from "../constants/dateFormat";
import { parse } from 'url';

export const paramDefaults = {
  bgColor: '#ffffff',
  txtColor: '#000000',
  hghTxtColor: '#f94f00',
  vacationFrom: null,
  vacationTo: null,
  weekend: false,
  holiday: false,
};

export function toParamsForUrl(params) {
  const result = {
    bgColor: params.bgColor,
    txtColor: params.txtColor,
    hghTxtColor: params.hghTxtColor,
    consumption: params.consumption,
    business: params.business,
    weekend: !!params.weekend,
    holiday: !!params.holiday,
    startDate: moment(params.startDate).format(DATE_FORMAT),
    holidayConsumption: params.holidayConsumption,
    alternativeUnits: params.alternativeUnits
  };

  result.shiftFrom = moment(params.shiftFrom).format('HHmm');
  result.shiftTo = moment(params.shiftTo).format('HHmm');

  if (params.vacations && params.vacationFrom) {
    result.vacationFrom = moment(params.vacationFrom).format('DD.MM');
  }
  if (params.vacations && params.vacationTo) {
    result.vacationTo = moment(params.vacationTo).format('DD.MM');
  }

  return result;
}

export function toParamsFromUrl(url) {
  if (!url) {
    return null;
  }

  const params = parse(url, true).query;
  if (!validateParams(params)) {
    return null;
  }

  return {
    bgColor: params.bgColor,
    txtColor: params.txtColor,
    hghTxtColor: params.hghTxtColor,
    consumption: parseInt(params.consumption, 10),
    business: params.business,
    weekend: params.weekend === 'true' || params.weekend === true,
    holiday: params.holiday === 'true' || params.holiday === true,
    shiftFrom: params.shiftFrom,
    shiftTo: params.shiftTo,
    startDate: moment(params.startDate, DATE_FORMAT, true),
    vacationFrom: !isNil(params.vacationFrom) && moment(params.vacationFrom + '.' + moment().get('year'), DATE_FORMAT, true),
    vacationTo: !isNil(params.vacationTo) && moment(params.vacationTo + '.' + moment().get('year'), DATE_FORMAT, true),
    holidayConsumption: !isNil(params.holidayConsumption) && parseFloat(params.holidayConsumption),
    alternativeUnits: params.alternativeUnits
  }
}

const isColor = (val) => {
    const s = new Option().style;
    s.color = val;
    return !!s.color;
};

// TODO: mozny bug ak dovolenka padne na prechodny rok
const isValidVacationDate = (val) => moment(val + '.' + moment().get('year'), DATE_FORMAT, true).isValid();
const isValidShift = (val) => /[0-2][0-9][0-5][0-9]/.test(val);
const isValidDate = (val) => moment(val, DATE_FORMAT, true).isValid();

function isNil(value) {
  return value === undefined || value === null || value === '';
}

export const validateParams = (params) => {
  // Check required fields are present
  if (isNil(params.consumption) || isNil(params.business) || isNil(params.shiftFrom) || isNil(params.shiftTo)) {
    console.error('Missing required fields: "consumption", "business", "shiftFrom", "shiftTo"');
    return false;
  }

  // CHeck format of params
  const consumption = Number(params.consumption);
  if (isNaN(consumption) || consumption < 0) {
    console.error('Invalid "consumption" value');
    return false;
  }

  if (!Object.values(Business).includes(params.business)) {
    console.error('Invalid "business" value');
    return false;
  }

  if (!isNil(params.startDate) && !isValidDate(params.startDate)) {
    console.error('Invalid "startDate" value');
    return false;
  }

  if (!isNil(params.bgColor) && !isColor(params.bgColor)) {
    console.error('Invalid "bgColor" value');
    return false;
  }

  if (!isNil(params.txtColor) && !isColor(params.txtColor)) {
    console.error('Invalid "txtColor" value');
    return false;
  }

  if (!isNil(params.hghTxtColor) && !isColor(params.hghTxtColor)) {
    console.error('Invalid "hghTxtColor" value');
    return false;
  }

  if (!isNil(params.weekend) && 
    !(params.weekend === 'true' || params.weekend === 'false' || params.weekend === true || params.weekend === false)) {
    console.error('Invalid "weekend" value');
    return false;
  }

  if (!isNil(params.holiday) &&
    !(params.holiday === 'true' || params.holiday === 'false' || params.holiday === true || params.holiday === false)) {
    console.error('Invalid "holiday" value');
    return false;
  }

  if (!isNil(params.vacationFrom) && !isValidVacationDate(params.vacationFrom)) {
    console.error('Invalid "vacationFrom" value');
    return false;
  }

  if (!isNil(params.vacationTo) && !isValidVacationDate(params.vacationTo)) {
    console.error('Invalid "vacationTo" value');
    return false;
  }

  if (isNil(params.vacationTo) && !isNil(params.vacationFrom) ||
    !isNil(params.vacationTo) && isNil(params.vacationFrom)) {
    console.error('Values of none or both "vacationFrom" and "vacationTo" must be provided');
    return false;
  }

  if (!isNil(params.holidayConsumption)) {
    const holidayConsumption = Number(params.holidayConsumption);
    if (isNaN(holidayConsumption) || holidayConsumption < 0 || holidayConsumption > 1) {
      console.error('Invalid "holidayConsumption" value');
      return false;
    }
  }

  if (!isNil(params.shiftFrom) && !isValidShift(params.shiftFrom)) {
    console.error('Invalid "shiftFrom" value');
    return false;
  }

  if (!isNil(params.shiftTo) && !isValidShift(params.shiftTo)) {
    console.error('Invalid "shiftTo" value');
    return false;
  }

  if (!isNil(params.shiftFrom) && isNil(params.shiftTo) ||
    isNil(params.shiftFrom) && !isNil(params.shiftTo)) {
    console.error('Values of none or both "shiftFrom" and "shiftTo" must be provided');
    return false;
  }

  return true;
}

export function pivotFor(name) {
  let pivot = 0;
  switch (name) {
      case 'cars':
          pivot = 1;
          break;
      case 'coal':
          pivot = 2;
          break;
      case 'forrest':
          pivot = 4;
          break;
      case 'soccer':
          pivot = 8;
          break;
      case 'baloon':
          pivot = 16;
          break;
      case 'chimney':
          pivot = 32;
          break;
  }
  return pivot;
}