import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Calculation from "./containers/Calculation";
import Admin from "./containers/Admin";

const App = () => (
    <Router>
        <Switch>
            <Route exact path="/">
                <Calculation />
            </Route>
            <Route exact path="/admin">
                <Admin />
            </Route>
            <Route>
                <Redirect to="/admin" />
            </Route>
        </Switch>
    </Router>
);

export default App;
