import mirror from "../utils/mirror";

export const Business = mirror([
    'AUTOMOTIVE',
    'BUILDINGS',
    'BANKS',
    'SERVICES',
    'GROCERY',
    'MINING',
    'METALLURGY',
    'OTHER'
]);

export const BusinessConfig = {
    [Business.AUTOMOTIVE]: [1, 0.85, 1.35, 1.1, 0.9, 1.1, 0.7, 1.3, 1.1, 1, 0.9, 0.7],
    [Business.BUILDINGS]: [0.6, 0.7, 0.8, 0.9, 1.2, 1.5, 1.5, 1.5, 1, 0.9, 0.8, 0.6],
    [Business.BANKS]: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
    [Business.SERVICES]: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
    [Business.GROCERY]: [0.6, 0.6, 0.6, 0.8, 1, 1.2, 1.4, 1.3, 0.3, 0.3, 1.9, 2],
    [Business.MINING]: [0.6, 0.7, 0.8, 0.9, 1.2, 1.5, 1.5, 1.5, 1, 0.9, 0.8, 0.6],
    [Business.METALLURGY]: [0.6, 0.6, 0.7, 0.8, 0.9, 1.2, 1.5, 1.5, 1.5, 1, 0.9, 0.8],
    [Business.OTHER]: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
}

export const BusinessLabel = {
    [Business.AUTOMOTIVE]: 'Automotive',
    [Business.BUILDINGS]: 'Stavebnictví',
    [Business.BANKS]: 'Bankovnictví',
    [Business.SERVICES]: 'Služby',
    [Business.GROCERY]: 'Výroba potravin',
    [Business.MINING]: 'Těžba',
    [Business.METALLURGY]: 'Hutnictví',
    [Business.OTHER]: 'Ostatní',
}