import * as firebase from "firebase/app";

// Add the Firebase products that you want to use
import "firebase/analytics";
import "firebase/remote-config";

const firebaseConfig = {
    apiKey: "AIzaSyDKDnrek43F8fYxKjmsXdqN7xL1V0xydY0",
    authDomain: "cez-calc.firebaseapp.com",
    databaseURL: "https://cez-calc.firebaseio.com",
    projectId: "cez-calc",
    storageBucket: "cez-calc.appspot.com",
    messagingSenderId: "419408979939",
    appId: "1:419408979939:web:039252ba61a16e1743a2d6",
    measurementId: "G-HYF43Z26DV"
};

export const ConfigKeys = {
    CONSUMPTION_RATIOS: 'consumptionRatios',
}

let remoteConfig;
let analytics;

export const initFirebase = () => {
    firebase.initializeApp(firebaseConfig);

    remoteConfig = firebase.remoteConfig();
    remoteConfig.settings = {
        minimumFetchIntervalMillis: 60000, // 1 min
    };
    remoteConfig.defaultConfig = ({
        'consumptionRatios': `{
            "1980":594.87,
            "2019":594.87,
            "2020":594.87,
            "2021":594.87,
            "2022":594.87
        }`,
    });
    remoteConfig.fetchAndActivate()
        .then(() => {
            console.debug('Remote config fetched successfully')
        })
        .catch((err) => {
            console.error(err);
        });

    analytics = firebase.analytics();
}

export const getConfigValue = (key) => remoteConfig.getString(key);
