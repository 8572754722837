import {toParamsForUrl} from "./params";

const toQueryString = (params = {}) => {
    return Object.keys(params).map((k) => `${k}=${encodeURIComponent(params[k])}`)
        .join('&');
}

const createIFrameUrl = (params) => {
    return `${window.location.protocol}//${window.location.host}/?${toQueryString(toParamsForUrl(params))}`;
};

export default createIFrameUrl;
