
import React from 'react'
import {pivotFor} from './../utils/params';
import {
    emissionsAsCars,
    emissionsAsCoal,
    emissionsAsForrest,
    emissionsAsSoccer,
    emissionsAsBalloons,
    emissionsAsChimney,
    humanizeArea,
    consumptionRatioForYear,
} from './../utils/calculation';
import moment from 'moment';
import numeral from 'numeral';

const AlternativeUnits = ({
  emissions, units, txtColor, hghTxtColor
}) => {
    const consumptionRation = consumptionRatioForYear(moment().get('year'));
    const asForrest = emissionsAsForrest(emissions);
    const asArea = humanizeArea(asForrest);
    const asCoal = emissionsAsCoal(emissions, consumptionRation);

    return (
    <>{
        emissions !== null && units ?
        <div>
        {
            (units & pivotFor('cars')) > 0 ?
            <div style={{
                marginBottom: '0.5rem',
                color: `${txtColor}`
            }}>
                Uspořené CO<sub style={{fontSize: "0.5em", fontWeight: "bold" }}>2</sub> odpovídá hmotnosti <span style={{color: `${hghTxtColor}`, fontWeight: "bold"}}>{numeral(emissionsAsCars(emissions)).format('0,0.00')}</span> osobních aut.
            </div>
            :
            null
        }
        {
            (units & pivotFor('coal')) > 0 ?
            <div style={{
                marginBottom: '0.5rem',
                color: `${txtColor}`
            }}>
                Uspořeno <span style={{color: `${hghTxtColor}`, fontWeight: "bold"}}>{numeral(asCoal[0]).format('0,0.00')}</span> {asCoal[1]} nespáleného uhlí.
            </div>
            :
            null
        }
        {
            (units & pivotFor('forrest')) > 0 ?
            <div style={{
                marginBottom: '0.5rem',
                color: `${txtColor}`
            }}>
                Plocha lesa nutná k absorbci CO<sub style={{fontSize: "0.5em", fontWeight: "bold"}}>2</sub> za rok by byla <span style={{color: `${hghTxtColor}`, fontWeight: "bold"}}>
                {numeral(asArea[0]).format('0,0.00')}</span>&nbsp;
                {asArea[1]}, což je <span style={{color: `${hghTxtColor}`, fontWeight: "bold"}}>{numeral(emissionsAsSoccer(asForrest)).format('0,0.00')}</span> fotbalových hřišť.
            </div>
            :
            null
        }
        {
            (units & pivotFor('baloon')) > 0 ?
            <div style={{
                marginBottom: '0.5rem',
                color: `${txtColor}`
            }}>
                <span>Úspora CO<sub>2</sub> - přepočet na horkovzdušné balony</span>
                <span>{numeral(emissionsAsBalloons(emissions)).format('0,0.00')} ks.</span>
            </div>
            :
            null
        }
        {
            (units & pivotFor('chimney')) > 0 ?
            <div style={{
                marginBottom: '0.5rem',
                color: `${txtColor}`
            }}>
                <span>Jak vysoký by byl komín z CO2 o rozměrech m x m</span>
                <span>{numeral(emissionsAsChimney(emissions)[0]).format('0,0.00')} {emissionsAsChimney(emissions)[1]}</span>
            </div>
            :
            null
        }
        </div>
        : null
    }</>);
};

export default AlternativeUnits
