import moment from "moment";
import React, { useEffect, useState } from 'react';
import AlternativeUnits from "../components/AlternativeUnitsComponent";
import holidaysTemplate, { getHolidays } from "../constants/holidays";
import { dailyEmissionsForYear, calculateCurrentEmissions, humanizeWeight, numParts } from "../utils/calculation";
import { toParamsFromUrl, paramDefaults } from "../utils/params";
import useInterval from '../utils/useInterval';
import numeral from 'numeral';

const UPDATE_INTERVAL = 5000;

const Calculation = () => {
    const [dailyEmissions, setDailyEmissions] = useState(null);
    const [accumulatedEmissions, setAccumulatedEmissions] = useState(0);
    const [currentEmissions, setCurrentEmissions] = useState(null);
    const [delay, setDelay] = useState(null);
    const [prevTick, setPrevTick] = useState();
    const [params, setParams] = useState({
        bgColor: paramDefaults.bgColor,
        txtColor: paramDefaults.txtColor,
        hghTxtColor: paramDefaults.hghTxtColor,
    });

    const year = moment().get('year');
    const januaryFirst = moment().startOf('year');

    const initCalculation = (url = document.URL) => {
        const inputParams = toParamsFromUrl(url);
        if (inputParams) {
            let {
                consumption,
                business,
                vacationFrom,
                vacationTo,
                weekend: workOnWeekend,
                holiday: workOnHolidays,
                holidayConsumption,
                shiftFrom,
                shiftTo,
                startDate,
                bgColor,
                txtColor,
                hghTxtColor,
                alternativeUnits
            } = inputParams;

            const holidays = getHolidays(holidaysTemplate, year);

            const vacation = vacationFrom && vacationTo ? [vacationFrom, vacationTo] : [];

            // startDate is either this year of January 1st
            startDate = startDate ? (startDate.isBefore(januaryFirst) ? januaryFirst : startDate) : januaryFirst;
            // convert to seconds since SOD
            shiftFrom = parseInt(shiftFrom.substring(0, 2), 10) * 60 * 60 + parseInt(shiftFrom.substring(2, 4), 10) * 60;
            // convert to seconds since SOD
            shiftTo = parseInt(shiftTo.substring(0, 2), 10) * 60 * 60 + parseInt(shiftTo.substring(2, 4), 10) * 60;

            setParams({
                ...params,
                bgColor,
                txtColor,
                hghTxtColor,
                holidays,
                vacation,
                workOnWeekend,
                workOnHolidays,
                holidayConsumption,
                startDate,
                shiftFrom,
                shiftTo,
                alternativeUnits,
                consumption
            });

            const emissions = dailyEmissionsForYear(consumption, business, vacation, workOnWeekend, workOnHolidays, year, holidays, holidayConsumption, shiftFrom, shiftTo);

            setDailyEmissions(emissions);

            const now = moment();
            const dayOfYear = now.dayOfYear() - 1;
            let emissionsTillYesterdayEod = 0;
            for (let day = startDate.dayOfYear() - 1; day < dayOfYear; day++) {
                emissionsTillYesterdayEod += emissions[day].totalDailyEmission;
            }
            setAccumulatedEmissions(emissionsTillYesterdayEod);
            setCurrentEmissions(emissionsTillYesterdayEod + calculateCurrentEmissions(emissions, shiftFrom, shiftTo, startDate));

            setPrevTick(now);
            setDelay(UPDATE_INTERVAL);
        } else {
            setDailyEmissions(null);
            setDelay(null);
        }
    }

    useEffect(() => {
        window.cez = {
            refresh: initCalculation,
        }
        initCalculation();
        return () => setDelay(null);
    }, []);

    useInterval(() => {
        const now = moment();
        if (now.isAfter(params.startDate)) {
            const dayOfYearNow = now.dayOfYear() - 1;
            const dayOfYearPrevTick = prevTick.dayOfYear() - 1;
            let prevEmissions = accumulatedEmissions;
            if (dayOfYearNow !== dayOfYearPrevTick) {
                prevEmissions = accumulatedEmissions + dailyEmissions[dayOfYearPrevTick].totalDailyEmission;
                setAccumulatedEmissions(prevEmissions);
            }

            const currEmissions = calculateCurrentEmissions(dailyEmissions, params.shiftFrom, params.shiftTo, params.holidayConsumption, params.startDate);    
            setCurrentEmissions(prevEmissions + currEmissions);
            setPrevTick(now);
        }
    }, delay);

    const [weight, unit] = humanizeWeight(currentEmissions);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            padding: '1px',
            backgroundColor: params.bgColor,
        }}>
        {
            dailyEmissions !== null ?
            <>
                <div>
                    <span style={{
                        textAlign: 'center',
                        color: `${params.txtColor}`,
                        fontSize: '8vw'
                    }}>
                        Tento rok jsme již <b style={{color: `${params.hghTxtColor}`}}>ušetřili</b>
                    </span>
                </div>
                <div style={{
                    color: `${params.txtColor}`,
                    fontSize: '9vw',
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end"
                }}>
                    {numParts(numeral(weight).format('0,0.0000'))[0]}<small>,{numParts(numeral(weight).format('0,0.0000'))[1]}</small>&nbsp;{unit}&nbsp;
                    <span style={{color: `${params.hghTxtColor}`, fontWeight: "bold"}}>CO</span>
                    <span style={{color: `${params.hghTxtColor}`, fontWeight: "bold", fontSize: "0.5em", verticalAlign: '-1em'}}>2</span>
                    .
                </div>
                <div style={{
                    margin: "1rem 1rem 0rem",
                    color: `${params.txtColor}`,
                    fontSize: "4vw",
                    width: '90%'
                }}>
                    <AlternativeUnits
                        emissions={currentEmissions}
                        units={params.alternativeUnits}
                        txtColor={params.txtColor}
                        hghTxtColor={params.hghTxtColor}>
                    </AlternativeUnits>
                </div>
            </>
            :
            //"Prosim vyplňte povinné údaje"
            <></>
        }
        </div>
    );
};

export default Calculation;
