import React, {useState} from 'react'
import PropTypes from 'prop-types'

import { TextField } from '@material-ui/core'

import PickerDialog from '../components/PickerDialog';
import converters, {DEFAULT_CONVERTER} from "../utils/rgbConverters";

const ColorPicker = ({
    onChange,
    convert,

    name,
    id,
    hintText,
    placeholder,
    floatingLabelText,
    label,
    TextFieldProps,
    value,
}) => {
    const [visible, setVisible] = useState(false);

    return (
        <>
            <div
                style={{
                    background: value,
                    height: 19,
                    width: 19,
                    display: 'inline-block',
                    cursor: 'pointer',
                    borderWidth: 1,
                    borderColor: "black",
                    borderStyle: "solid",
                    marginRight: 5
                }}
                onClick={() => setVisible(true)}
            />
            <TextField
                name={name}
                id={id}
                label={floatingLabelText || label}
                placeholder={hintText || placeholder}
                onClick={() => setVisible(true)}
                onChange={e => {
                    onChange(e.target.value)
                }}
                value={value}
                {...TextFieldProps}
            />
            {visible && (
                <PickerDialog
                    value={value}
                    onClick={() => {
                        setVisible(false)
                        onChange(value)
                    }}
                    onChange={c => {
                        const newValue = converters[convert](c)
                        onChange(newValue)
                    }}
                />
            )}
        </>
    );
}

ColorPicker.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    convert: PropTypes.oneOf(Object.keys(converters)),
    name: PropTypes.string,
    id: PropTypes.string,
    hintText: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    floatingLabelText: PropTypes.string,
    TextFieldProps: PropTypes.shape(TextField.propTypes),
    showPicker: PropTypes.bool,
    setShowPicker: PropTypes.func,
    internalValue: PropTypes.string,
    setValue: PropTypes.func
}

ColorPicker.defaultProps = {
    convert: DEFAULT_CONVERTER
}

export default ColorPicker;
