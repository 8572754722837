import React from 'react';
import {Button, TextField, MenuItem, Switch, Typography} from '@material-ui/core';
import {ResizableBox} from 'react-resizable';
import ColorPicker from './ColorPicker';

import 'react-resizable/css/styles.css';
import createIFrameUrl from "../utils/urlMaker";
import SyntaxHighlighter from 'react-syntax-highlighter';
import {a11yLight as codeStyle} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {Business, BusinessLabel} from "../constants/business";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {DatePicker, TimePicker} from "@material-ui/pickers";
import moment from "moment";
import DATE_FORMAT from "../constants/dateFormat";
import Slider from "@material-ui/core/Slider";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import {paramDefaults, pivotFor} from '../utils/params';

import cezLogo from '../assets/img/CEZ_ESCO_logo.svg';
import bvLogo from '../assets/img/bureau_veritas_logo.png';

const dateFormatter = (date) => `${date.format(DATE_FORMAT)}`;

class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.iframe = React.createRef();

        this.state = {
            year: moment().year(),
            width: 500,
            height: 200,
            config: {
                bgColor: paramDefaults.bgColor,
                txtColor: paramDefaults.txtColor,
                hghTxtColor: paramDefaults.hghTxtColor,
                consumption: 0,
                business: '',
                weekend: paramDefaults.weekend,
                holiday: paramDefaults.holiday,
                vacations: false,
                shiftFrom: moment().hours(8).minutes(0),
                shiftTo: moment().hours(16).minutes(0),
                startDate: moment(),
                holidayConsumption: 0.1,
                vacationFrom: moment('14.9.2021', DATE_FORMAT),
                vacationTo: moment('20.9.2021', DATE_FORMAT),
                alternativeUnits: 0
            },
            consumptionError: null,
            snackbarOpened: false,
        }
    }

    refresh = () => {
        const iframe = this.iframe.current
        if (iframe && iframe.contentWindow) {
            const win = iframe.contentWindow;
            if (win.cez && win.cez.refresh && typeof win.cez.refresh === 'function') {
                win.cez.refresh(this.getIFrameUrl());
            }
        }
    }

    handleWidthChange = (width) => this.setState({width: Number(width)})
    handleHeightChange = (height) => this.setState({height: Number(height)})
    handleTextConfigChange = (e) => this.updateConfig(e.target.name, e.target.value);
    handleSwitchConfigChange = (e) => this.updateConfig(e.target.name, e.target.checked);
    handleDateInputChange = (key, value) => this.updateConfig(key, value);
    handleColorChange = (key, c) => this.updateConfig(key, c);

    handleAlternativeUnitsChange = (e) => {
        let pivot = pivotFor(e.target.name);
        if (e.target.checked) {
            this.updateConfig('alternativeUnits', this.state.config.alternativeUnits | pivot);
        } else {
            this.updateConfig('alternativeUnits', this.state.config.alternativeUnits & ~pivot);
        }
    }

    updateConfig = (name, value) => this.setState({
        config: {
            ...this.state.config,
            [name]: value,
        }
    }, this.refresh);

    handleConsumptionChange = (e) => {
        const c = parseInt(e.target.value, 10);
        const isInvalid = isNaN(c) || c <= 0;
        this.setState({
            consumptionError: isInvalid ? 'Spotřeba musí být kladné číslo' : null,
            config: {
                ...this.state.config,
                consumption: isInvalid ? e.target.value : c,
            }
        }, this.refresh);
    };

    handleCopy = (value) => {
        const input = document.createElement("textarea");
        input.value = value;

        // Avoid scrolling to bottom
        input.style.top = "0";
        input.style.left = "0";
        input.style.position = "fixed";

        document.body.appendChild(input);

        input.focus();
        input.select();
        input.setSelectionRange(0, 99999); /*For mobile devices*/

        document.execCommand("copy");

        document.body.removeChild(input);

        this.setState({snackbarOpened: true});
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({snackbarOpened: false});
    };

    getIFrameUrl = () => createIFrameUrl(this.state.config);

    render() {
        const {
            width,
            height,
            config: {
                bgColor,
                txtColor,
                hghTxtColor,
                consumption,
                business,
                weekend,
                holiday,
                vacations,
                shiftFrom,
                shiftTo,
                startDate,
                holidayConsumption,
                vacationFrom,
                vacationTo,
                alternativeUnits,
            },
            snackbarOpened
        } = this.state;

        const iFrameUrl = this.getIFrameUrl()
        const isDataValid = !!iFrameUrl;
        const iFrameSrc = isDataValid ? `<iframe src="${iFrameUrl}" width="${width}px" height="${height}px" title="cez-calc"/>` : 'Neplatná data na vstupu';

        return (
            <div className="calc--getWrapperFromVariant">
                <div className="header">
                    <img
                        src={cezLogo}
                        alt=""
                        className="logo"
                    />
                    <h1 className="logo-title">ESCO</h1>
                    <h1>Kalkulačka snížení CO<sub style={{fontSize: "0.8rem", fontWeight: "bold" }}>2</sub> stopy</h1>
                </div>

                <div className="content">
                    <p>Tato kalkulačka slouží k vytvoření vizualizace <b style={{color: "#f24f00"}}>roční úspory CO<sub style={{fontSize: "0.8rem", fontWeight: "bold"}}>2</sub> při
                        odběru Bezemisní elektřiny od ČEZ ESCO</b>, a následné reprezentaci této úspory návštěvníkům vašich
                        webových stránek.
                    </p>
                    <p className="mb-0">
                        Po zadání údajů o vaší společnosti dojde k vytvoření kódu, který si zkopírujte. Vaši weboví správci jej následně musí vložit do webových stránek vaší společnosti. Umístění kódu (tj. tabulky s výslednou úsporou) na daných stránkách záleží na vás.
                    </p>
                </div>

                <div className="admin d-flex--row">
                    <div className="input-wrapper m-1 col-md-12">
                        <div className="data-inputs">
                            <h2>Zadejte údaje o vaší společnosti pro výpočet úspory CO<sub style={{fontSize: "0.9rem"}}>2</sub></h2>
                            <div className="data-inputs--wrapper">
                                <TextField name="business" id="select" label="Odvětví" value={business}
                                           onChange={this.handleTextConfigChange} select fullWidth InputLabelProps={{shrink: true}}>
                                    {
                                        Object.values(Business).map((b) => <MenuItem value={b}
                                                                                     key={b}>{BusinessLabel[b]}</MenuItem>)
                                    }
                                </TextField>
                                <DatePicker
                                    value={startDate}
                                    onChange={(d) => this.handleDateInputChange('startDate', d)}
                                    views={["date"]}
                                    labelFunc={dateFormatter}
                                    label="Začátek odběru bezemisní elektřiny"
                                />
                                <TextField
                                    type="text"
                                    name="consumption"
                                    label="Očekávaná roční spotřeba bezemisní elektřiny (MWh)"
                                    error={this.state.consumptionError !== null}
                                    value={consumption}
                                    onChange={this.handleConsumptionChange}
                                    helperText={this.state.consumptionError}
                                    fullWidth
                                />

                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="weekend"
                                            checked={weekend}
                                            onChange={this.handleSwitchConfigChange}
                                        />
                                    }
                                    label="Provoz o víkendu"
                                    labelPlacement="start"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="holiday"
                                            checked={holiday}
                                            onChange={this.handleSwitchConfigChange}
                                        />
                                    }
                                    label="Provoz o svátcích"
                                    labelPlacement="start"
                                />
                                <div>
                                    <TimePicker
                                        value={shiftFrom}
                                        onChange={(d) => this.handleDateInputChange('shiftFrom', d)}
                                        ampm={false}
                                        minutesStep={15}
                                        label="Pracovní doba od"
                                    />
                                    <TimePicker
                                        value={shiftTo}
                                        onChange={(d) => this.handleDateInputChange('shiftTo', d)}
                                        ampm={false}
                                        minutesStep={15}
                                        label="Pracovní doba do"
                                    />
                                </div>
                                <Typography id="holiday-consumption-label" gutterBottom>Spotřeba mimo provoz</Typography>
                                <Slider
                                    value={Math.floor(100 * holidayConsumption)}
                                    onChange={(e, v) => this.updateConfig('holidayConsumption', v / 100)}
                                    min={0}
                                    max={100}
                                    valueLabelDisplay="on"
                                    valueLabelFormat={(l) => `${l} %`}
                                    aria-labelledby="holiday-consumption-label"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="vacations"
                                            checked={vacations}
                                            onChange={this.handleSwitchConfigChange}
                                        />
                                    }
                                    label="Celozávodní dovolená"
                                    labelPlacement="start"
                                />

                                {vacations &&
                                <div>
                                    <DatePicker
                                        value={vacationFrom}
                                        onChange={(d) => this.handleDateInputChange('vacationFrom', d)}
                                        views={["date"]}
                                        labelFunc={dateFormatter}
                                        label="Dovolená od"
                                    />
                                    <DatePicker
                                        value={vacationTo}
                                        onChange={(d) => this.handleDateInputChange('vacationTo', d)}
                                        views={["date"]}
                                        labelFunc={dateFormatter}
                                        label="Dovolená do"
                                    />
                                </div>
                                }
                                <div style={{marginTop: "12px"}}><b>Rozšířené možnosti</b></div>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="cars"
                                            checked={(alternativeUnits & pivotFor('cars')) > 0}
                                            onChange={this.handleAlternativeUnitsChange}
                                        />
                                    }
                                    label="Příměr k osobním autům"
                                    labelPlacement="start"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="coal"
                                            checked={(alternativeUnits & pivotFor('coal')) > 0}
                                            onChange={this.handleAlternativeUnitsChange}
                                        />
                                    }
                                    label="Příměr k uhlí"
                                    labelPlacement="start"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="forrest"
                                            checked={(alternativeUnits & pivotFor('forrest')) > 0}
                                            onChange={this.handleAlternativeUnitsChange}
                                        />
                                    }
                                    label="Příměr k ploše lesa"
                                    labelPlacement="start"
                                />
                                {/**
                                 <FormControlLabel
                                 control={
                                <Switch
                                    name="soccer"
                                    checked={(alternativeUnits & pivotFor('soccer')) > 0}
                                    onChange={this.handleAlternativeUnitsChange}
                                />
                            }
                                 label="Příměr k ploche fotbalového hřišťe"
                                 labelPlacement="start"
                                 />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="baloon"
                                            checked={(alternativeUnits & pivotFor('baloon')) > 0}
                                            onChange={this.handleAlternativeUnitsChange}
                                        />
                                    }
                                    label="Příměr k balónům"
                                    labelPlacement="start"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="chimney"
                                            checked={(alternativeUnits & pivotFor('chimney')) > 0}
                                            onChange={this.handleAlternativeUnitsChange}
                                        />
                                    }
                                    label="Příměr ke komínu"
                                    labelPlacement="start"
                                />*/}
                            </div>
                        </div>
                    </div>

                    <div className="sidebar--wrapper d-flex--column m-1 p-2">
                        <h2 className="mb-2">Ukázka výsledného zobrazení úspory CO<sub style={{fontSize: "0.9rem"}}>2</sub></h2>
                        <p>Výsledné zobrazení si můžete graficky upravit tak, aby co nejvíce ladilo s designem vašich
                            webových stránek</p>
                        <div className=" d-flex--column mb-2">
                            <div className="mb-1 d-flex align-items-center">
                                <div className="d-flex--row">
                                    <div className="data-inputs--wrapper m-1">
                                        <ColorPicker
                                            name='bgColor'
                                            value={bgColor}
                                            TextFieldProps={{value: bgColor}}
                                            onChange={(c) => this.handleColorChange('bgColor', c)}
                                            label="Barva pozadí"
                                        />
                                        <ColorPicker
                                            name='txtColor'
                                            value={txtColor}
                                            TextFieldProps={{value: txtColor}}
                                            onChange={(c) => this.handleColorChange('txtColor', c)}
                                            label="Barva textu"
                                        />
                                        <ColorPicker
                                            name='hghTxtColor'
                                            value={hghTxtColor}
                                            TextFieldProps={{value: hghTxtColor}}
                                            onChange={(c) => this.handleColorChange('hghTxtColor', c)}
                                            label="Barva zvýrazněného textu"
                                        />
                                    </div>
                                    <div className="data-inputs--wrapper m-1">
                                        <TextField label="Šířka" type="number" value={width}
                                                   onChange={(e) => this.handleWidthChange(e.target.value)}/>
                                        <TextField label="Výška" type="number" value={height}
                                                   onChange={(e) => this.handleHeightChange(e.target.value)}/>
                                    </div>
                                </div>
                            </div>
                            <ResizableBox width={width} height={height} onResize={(e, {size: {width, height}}) => {
                                this.handleWidthChange(width)
                                this.handleHeightChange(height)
                            }}>
                                <iframe src="/" ref={this.iframe} width={width} height={height} title="cez-calc"
                                        style={{ overflow: 'hidden' }}/>
                            </ResizableBox>
                            <div>
                                <SyntaxHighlighter language="htmlbars" style={codeStyle}>
                                    {iFrameSrc}
                                </SyntaxHighlighter>
                            </div>
                            <p>Zkopírujte kód pro vložení do vašich webových stránek</p>
                            <Button
                                onClick={() => this.handleCopy(iFrameSrc)}
                                color="primary"
                                variant="contained"
                                disabled={!isDataValid}
                            >Kopírovat</Button>
                            <Snackbar
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                open={snackbarOpened}
                                autoHideDuration={6000}
                                onClose={this.handleClose}
                                message="Zkopírováno"
                                action={
                                    <React.Fragment>
                                        <Button color="secondary" size="small" onClick={this.handleClose}>
                                            Zavřít
                                        </Button>
                                        <IconButton size="small" aria-label="close" color="inherit"
                                                    onClick={this.handleClose}>
                                            <CloseIcon fontSize="small"/>
                                        </IconButton>
                                    </React.Fragment>
                                }
                            />
                        </div>
                    </div>
                </div>

                <div className="bvLogo">
                    <img src={bvLogo} alt="" className="logo"/>
                    <span>Tato kalkulačka je ověřena společností Bureau Veritas</span>
                </div>

                <div className="footer mb-2">
                    <p className="legalese">Tato kalkulačka složí pouze pro orientační vizualizaci roční úspory CO2 při využití bezemisní elektřiny od ČEZ ESCO, a.s. a není zachycením reálné úspory CO2 při využití bezemisní elektřiny od ČEZ ESCO, a.s., jelikož ta závisí na Vaší skutečné spotřebě a konkrétních smluvních ujednáních mezi Vámi a ČEZ ESCO, a.s., jakožto obchodníka, od kterého odebíráte bezemisní elektřinu.</p>
                    <p className="legalese">Tato kalkulačka vizualizuje úspory CO2 při využití bezemisní elektřiny od ČEZ ESCO, a.s. na základě Vámi zadaných dat o odběru elektřiny. Za správnost zadaných dat a potažmo taktéž výsledků kalkulace nesete odpovědnost Vy, jelikož ČEZ ESCO, a.s. není s to ověřit správnost Vámi manuálně zadávaných údajů. Využitím kalkulačky Vám nevzniká žádný nárok na dodání bezemisní elektřiny, úsporu při Vašem odběru elektřiny, ani žádný jiný nárok vůči ČEZ ESCO, a.s. Využitím kalkulačky a jejích výstupů  se zavazujete, že kalkulačku a její výstupy použijete pouze za účelem orientačního informování o Vaší roční úspoře CO2 při využití bezemisní elektřiny od ČEZ ESCO, a.s. Vám, ani žádné třetí osobě nevzniká právo využít kalkulačku za jiným účelem bez předchozího písemného souhlasu ČEZ ESCO, a.s. Pokud k tomuto dojde, náleží ČEZ ESCO, a.s. náhrada případně vzniklé škody. Využitím kalkulačky berete uvedené na vědomí a souhlasíte s tím.</p>
                    <p style={{ color: "#63666a", marginTop: "0.5rem" }}>Copyright {this.state.year} ČEZ, a. s. | Všechna práva vyhrazena</p>
                </div>
            </div>

        );
    }
}

export default Admin;
